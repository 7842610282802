
<template>
  
  <Box> <!-- root -->
    <ErrorRow :error="error" />
    
    <Row v-if="isAdmin" :showBorder="true">
    
      <Column :width="15" >
        <div class="container" >
        
          <Row />
          <div class="row">
          
            <div class="rc-box-col-2 rc-font-medium">
            {{ catelog.get(catelogKeys.FILTERS.TIME) }}
            </div>
            
            <div class="rc-box-col-1">
              <Button 
                v-on:click="setFilter(FILTER.ALL)" 
                :disabled="isFilter(FILTER.ALL)" >
              All
              </Button>
            </div>

            <div class="rc-box-col-2">
              <Button 
                v-on:click="setFilter(FILTER.LAST_MONTH)" 
                :disabled="isFilter(FILTER.LAST_MONTH)">
              Last Month
              </Button>
            </div>

            <div class="rc-box-col-2">
            </div>
                        
            <div class="rc-box-col-2">
            <!--
              <Button 
                v-on:click="setFilter(FILTER.LAST_QUARTER)" 
                :disabled="isFilter(FILTER.LAST_QUARTER)"
                label="Last Quarter" />
            -->
            </div>
            
            <div class="rc-box-col-5">
              <div class="container">
                <Label v-if="isAdmin" :label="totalForTime" />
              </div>
            </div>
            
            <div class="rc-box-col-1">
              <Button 
                v-on:click="refresh" 
                :disabled="isLoading">
              Refresh
              </Button>
            </div>
          
          </div> <!-- row -->
        
          <div class="row" v-if="isAdmin">
            <div class="rc-box-col-2 rc-font-medium">Total</div>
            <div class="rc-box-col-1">
              <Button 
                v-on:click="setAmountFilter('all')" 
                :disabled="isAmountFilter('all')">
              All
              </Button>
            </div>

            <div class="rc-box-col-1">
              <Button 
                v-on:click="setAmountFilter('gtZero')" 
                :disabled="isAmountFilter('gtZero')">
              &gt; 0
              </Button>
            </div>
            <div class="rc-box-col-12" />
          </div>
            
          <div class="row" v-if="isAdmin">
            <div class="rc-box-col-2 rc-font-medium">Contract</div>
            <div class="rc-box-col-1">
              <Button 
                v-on:click="setContractStartDateFilter('all')" 
                :disabled="isContractStartDateFilter('all')">
              All
              </Button>
            </div>
            <div class="rc-box-col-2">
              <Button 
                v-on:click="setContractStartDateFilter('gtZero')" 
                :disabled="isContractStartDateFilter('gtZero')"> 
                Contract Start > 0
              </Button>
            </div>
            <div class="rc-box-col-10" />
          </div>
          
          <div class="row" v-if="isAdmin">
            <div class="rc-box-col-2 rc-font-medium">Promo Code</div>
            <div class="rc-box-col-1">
              <Button 
                v-on:click="setPromoCodeFilter('all')" 
                :disabled="isPromoCodeFilter('all')">
              All
              </Button>
            </div>
            <div class="rc-box-col-2">
              <Button 
                v-on:click="setPromoCodeFilter('notEmpty')" 
                :disabled="isPromoCodeFilter('notEmpty')">
              Not Empty
              </Button>
            </div>
            <div class="rc-box-col-1" v-for="(code, codeIndex) in promoCodes" :key="codeIndex + 'pc'">
              <Button 
                v-on:click="setPromoCodeFilter(code)" 
                :disabled="isPromoCodeFilter(code)">
              {{ code }}
              </Button>
            </div>
          </div>

          <div class="row" v-if="isAdmin">
            <div class="rc-box-col-2 rc-font-medium">Charge Status</div>
            <div class="rc-box-col-1">
              <Button 
                v-on:click="setChargeStatusFilter('all')" 
                :disabled="isChargeStatusFilter('all')">
              All
              </Button>
            </div>
            <div class="rc-box-col-1">
              <Button 
                v-on:click="setChargeStatusFilter('bad')" 
                :disabled="isChargeStatusFilter('bad')">
              Bad
              </Button>
            </div>
            <div class="rc-box-col-1">
              <Button 
                v-on:click="setChargeStatusFilter('empty')" 
                :disabled="isChargeStatusFilter('empty')">
              Empty
              </Button>
            </div>
          </div>
          <div class="rc-box-col-10" />
          
        </div> <!-- Inner container -->
      </Column>

    </Row>
  
    <TitleRow class="rc-text-small">
      <div class="rc-box-col-2 ">
       Invoice #
      </div>
      <div class="rc-box-col-2">
        Issued On
      </div>
      <div class="rc-box-col-2 rc-text-centre">
        PreTax Total
      </div>
      <div class="rc-box-col-1 rc-text-centre">
       Total
      </div>
      <div class="rc-box-col-2 rc-text-centre">
        Payments 
      </div>
      <div class="rc-box-col-2 rc-text-centre">
        Remaining
      </div>

      <div class="rc-box-col-2 rc-text-centre">
       Due
      </div> 
      <div class="rc-box-col-1 rc-text-centre">
       Invoice
      </div>
      <div class="rc-box-col-1">
       Paid
      </div>
    </TitleRow>
    
    <Row :showBorder="true">
      <Column>
        
        <Box>
          <div v-for="(invoice, index) in invoices" v-bind:key="invoice['@rid']" :class="isEven(index) ? 'rowEven row' : 'rowOdd row'" style="padding: 8px">
            <div class="rc-box-col-15">
              <InvoiceRow :invoiceData="invoice" :isAdmin="isAdmin" />
            </div>
          </div>
        </Box>
        
      </Column>
    </Row>
        
    <LoadingRow :showBorder="true" v-if="isLoading">{{ loadingMessage }}</LoadingRow>

    <Row :showBorder="true" v-if="!isLoading" >
      <div class="rc-box-col-4" />
      <div class="rc-box-col-1">
        <Button v-if="canPreviousPage()" v-on:click="previousPage" > &lt; </Button>
      </div>
      
      <div class="rc-box-col-5 rc-text-centre rc-text-label">
        Showing {{ min }} to {{ max }}  of {{ count }}
      </div>
    
      <div class="rc-box-col-1">
        <Button v-if="canNextPage()" v-on:click="nextPage"> &gt; </Button>
      </div>
      <div class="rc-box-col-4" />
    </Row>
  
</Box> <!-- root -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

//import ConstUtils   from '@/utils/ConstUtils.js';
import StringUtils  from '@/utils/StringUtils.js';
import DateUtils  from '@/utils/DateUtils.js';
import ConnectionUtils      from '@/utils/ConnectionUtils.js';
import MC from "@/domain/session/MC.js";

import Label from '@/components/row/LabelRow.vue';
import ErrorRow from '@/components/row/ErrorRow.vue';

import Catalog from "@/domain/session/CanadianEnglish.js";

import InvoiceListFilter from "@/domain/model/invoice/InvoiceListFilter.js";
import InvoiceMap from "@/domain/model/invoice/InvoiceMap.js";
import Company from "@/domain/model/company/Company.js";
import InvoiceRow from "./InvoiceRow.vue";
import InvoiceUtils from "./InvoiceUtils.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  

//import Title from "@/portals/shared/library/title/Title.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";

//import Spacer from "@/portals/shared/library/spacer/Spacer.vue";

import Button from "@/portals/shared/library/button/Button.vue";
//import Value from "@/portals/shared/library/value/Value.vue";
//import ValueRow from "@/portals/shared/library/value/ValueRow.vue";

//import LabelValue from "@/portals/shared/library/labelvalue/LabelValue.vue";
//import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";

export default {
  name: 'portal-operator-invoices',
  components: {
    ErrorRow,
    InvoiceRow,
    LoadingRow,
    Button,
    Label,
    Box, Row, Column,
    TitleRow, 
    
  },
  props: {
    isAdmin: { type: Boolean, default: false },
    isLoadAll: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      list: [],
      isLoading: false,
      loadingMessage: null,
      MC: new MC(),
      count: 0,
      total: 0,
      pageSize: 80,
      pageNumber: 0,
      showSessionTimeoutDialog: false,
      catelogKeys: Catalog.KEYS,
      catelog: new Catalog(),
      currentDateFilter: null,
      currentAmountFilter: this.isAdmin ? 'gtZero' :'all',
      currentContractStartDateFilter:  this.isAdmin ? 'gtZero' :'all',
      currentPromoCodeFilter: 'all',
      currentChargeStatusFilter: 'all',
      promoCodes: ['TRI30', 'DV30', 'SD30', 'SRS30', 'ECC30', 'KCI30'],
      FILTER: {
        ALL: 'all',
        THIS_MONTH: 'thisMonth',
        LAST_MONTH: 'lastMonth',
        LAST_QUARTER: 'lastQuarter',
      }
    }
  },
  computed: {
    ...mapGetters([
        'auth_connected',
        'auth_socket_status',
        'signin_event',
        'auth_client',
        'domain',
      ]),
    company: function() {
      if (this.domain && this.domain.session()) {
        return this.domain.session().company();
      }
      return new Company();
    },
    invoices: function() {
      return this.list;
    },
    max: function() {
      var t = (this.pageNumber + 1) * this.pageSize;
      return this.count < t ? this.count : t;
    },
    min: function() {
      if (this.count == 0) {
        return 0;
      }
      return (this.pageNumber * this.pageSize) + 1;
    },
    totalForTime: function() {
      return "Sum $" + this.total.toFixed(2) + "";
    },
  },
  watch: {
    pageNumber() {
      this.loadByFilter();
    },
    
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.isLoading = false;
      this.startComponent = true;
      if (this.isAdmin) {
        this.currentDateFilter = this.FILTER.ALL;
      } else {
        this.currentDateFilter = this.FILTER.ALL;
      }
      this.loadByFilter();
    },
    
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    displayMessage: function() {
      if (this.domain.session().company().createdDate() < DateUtils.august12021()) {
        return "Invoices appear the first week of every month, invoices prior to Aug 2021 will not appear.";
      }
      return "Invoices appear the first week of every month";
    },
    isEven: function (position) {
      return StringUtils.isEven(position);
    },
    setFilter: function(filter) {
      this.currentDateFilter = filter;
      this.refresh();
    },
    isFilter: function(filter) {
      return this.currentDateFilter === filter;
    },
    setAmountFilter: function(filter) {
      this.currentAmountFilter = filter;
      this.refresh();
    },
    isAmountFilter: function(filter) {
      return this.currentAmountFilter === filter;
    },

    setContractStartDateFilter: function(filter) {
      this.currentContractStartDateFilter = filter;
      this.refresh();
    },
    isContractStartDateFilter: function(filter) {
      return this.currentContractStartDateFilter === filter;
    },

    setPromoCodeFilter: function(filter) {
      this.currentPromoCodeFilter = filter;
      this.refresh();
    },
    isPromoCodeFilter: function(filter) {
      return this.currentPromoCodeFilter === filter;
    },

    setChargeStatusFilter: function(filter) {
      this.currentChargeStatusFilter = filter;
      this.refresh();
    },
    isChargeStatusFilter: function(filter) {
      return this.currentChargeStatusFilter === filter;
    },

    refresh: function() {
      this.loadByFilter();
    },
    
    loadByFilter: function() {
      this.total = 0;
      if (!this.domain || !this.domain.session()) {
        return;
      }
      this.isLoading = true;
      this.loadingMessage = this.MC.Invoice.Status.Loading.value();
      this.list = [];
      var filter = new InvoiceListFilter();
      var company = this.domain.session().company();
      
      filter
        .withPagination(this.pageSize, this.pageNumber)
        .withDateRange(this.currentDateFilter)
        .withCompany(company.id())
        .done();
      
      
      var request = this.domain.events().invoice().list(filter);
      request.send(this.listener);
    },
    listener: function(event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        var map = payload.valuesFor(InvoiceMap.MODEL_NAME);
        this.count = payload.count();
        this.total = payload.total();
        this.list = Object.values(map.data).sort(InvoiceUtils.SortByCreatedDate);
      }
      this.isLoading = false;
    },
    previousPage: function() {
      if (this.canPreviousPage()) {
        this.pageNumber--;
        this.loadByFilter();
      }
    },
    canPreviousPage: function() {
      return this.pageNumber > 0;
    },
    canNextPage: function() {
      return this.count > (this.pageSize * this.pageNumber) + this.pageSize;
    },
    nextPage: function() {
      if (this.canNextPage()) {
        this.pageNumber++;
        this.loadByFilter();
      }
    },
  },
}
</script>